<template>
  <div class="success-wrapper">
    <div class="character">
      <img src="@assets/images/character.svg" alt="" />
    </div>
    <div class="content">
      <h2>Thank you!</h2>
      <p>
        Thank you for downloading an example, this will be send to your email address. This normally takes a few
        minutes.
      </p>
      <button @click="() => router.push('/')">Try now for free</button>
    </div>
  </div>
  <benefits-component />
</template>

<script setup lang="ts">
import BenefitsComponent from '@components/benefits/BenefitsComponent.vue'
import { useRouter } from 'vue-router'

const router = useRouter()

</script>

<style scoped lang="scss">
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.success-wrapper {
  width: 100%;
  margin: 44px auto 60px auto;
  height: wrap-content;

  h2 {
    margin-bottom: 13px;
  }

  @include xl {
    display: flex;
    gap: 34px;
  }

  .character {
    flex: 1;
    display: none;

    @include xl {
      position: relative;
      display: block;
      height: fit-content;
      height: 600px;

      img {
        width: 100%;
        position: absolute;
      }
    }
  }

  .content {
    flex: 1;

    button {
      margin-top: 20px;
    }
  }
}


</style>
