<template>
  <div class="reg-wrapper top-borders bottom-borders" v-if="store.showLogin">
    <div class="top top-borders"><h3>Log in</h3></div>
    <form class="bottom-borders" @submit="onSubmit">
      <div class="input-wrapper">
        <label>Email</label>
        <input type="email" placeholder="Email" v-model="email" name="email" autocomplete="true" />
      </div>
      <div class="input-wrapper">
        <label>Password</label>
        <input type="password" placeholder="Password" name="password" v-model="password" />
      </div>
      <p class="center error" v-if="accountVerified === false && !verificationEmailSent">
        Your account is not yet verified. We have sent you a link in your welcome email that you can use to verify your
        account. Didn't get the email?
        <span @click="resendVerification()" class="resend-verification">Resend verification email ></span>
      </p>
      <p class="center error" v-if="verificationEmailSent">
        Verification email sent to {{ email[0] }}*****@{{ email.split('@')[1] }}
      </p>
      <button type="submit" :disabled="!meta.valid">Log in</button>
      <div class="arrow">
        <router-link class="forgot-password" to="forgot-password">Forgot password?
        </router-link
        >
        <img class="chevron" src="@assets/icons/chevron-right.svg" />
      </div>
    </form>
    <GoogleButton text="Sign in with Google" @clicked="google" />
    <div class="arrow margin-bottom">
      <p class="center arrow" @click="switchToRegister">Don't have an account?</p>
      <img class="chevron" src="@assets/icons/chevron-right.svg" />
    </div>
  </div>
  <RegisterComponentVue v-else />
</template>

<script setup lang="ts">
import { redirectToGoogle } from '@/services/social-auth-service'
import GoogleButton from '@/components/ui/google-button/GoogleButton.vue'
import { useField, useForm } from 'vee-validate'
import { object, string } from 'yup'
import { useAuthStore } from '@/stores/auth'
import { useRouter } from 'vue-router'
import { ref, onMounted } from 'vue'
import RegisterComponentVue from '../RegisterComponent.vue'
import { useChallengeV3 } from 'vue-recaptcha'
import { getCSRFToken, resendVerificationEmail } from '../../../services/auth-service'
import { useCompanyStore } from '@stores/company'
import { useToastStore } from '@stores/toast'

const store = useAuthStore()
const companyStore = useCompanyStore()
const router = useRouter()
const returnUrl = ref('/')
const accountVerified = ref(null)
const verificationEmailSent = ref<boolean>(false)
const emit = defineEmits(['close'])
const { alert } = useToastStore()

const schema = object({
  email: string().email().required(),
  password: string().required()
})

const { meta, handleSubmit } = useForm({
  validationSchema: schema
})

const { value: email, errors: emailErrors } = useField<string>(() => 'email')
const { value: password, errors: passwordErrors } = useField(() => 'password')
const { execute } = useChallengeV3('submit')

const onSubmit = handleSubmit(async (values: any) => {
  verificationEmailSent.value = false
  accountVerified.value = null
  try {
    const token = await execute()
    const { user, verified } = await store.loginEmailUser({ ...values, captchaToken: token })
    if (user && verified) {
      await companyStore.getAllCompanies(user.id)
      const route = companyStore.myCompanies.length <= 0 ? '/create-project' : `/projects/${user.id}`
      await router.push(route)
    } else if (!verified) {
      accountVerified.value = false
    }
  } catch (error) {
    alert('Invalid email or password', 'error')
  }
})

const resendVerification = async () => {
  try {
    const res = resendVerificationEmail(email.value as string)
    verificationEmailSent.value = true
  } catch (error) {
    alert('Something went wrong, please try again', 'error')
  }
}

const google = async () => {
  const res = await redirectToGoogle()
  window.open(res, '_self')
}
const switchToRegister = () => {
  store.toggleRegister(true, false)
}

onMounted(() => {
  getCSRFToken()
  returnUrl.value = store.returnUrl
})
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.arrow {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;

  .chevron {
    height: 16px;
    width: 16px;
    margin-left: 0.5rem;
  }

  p {
    padding-bottom: 0;
  }
}

.margin-bottom {
  padding-bottom: 3rem;
}

.reg-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto 6.875rem auto;
  background-color: $grey-3;
  height: wrap-content;

  .top {
    background-color: $black;
    color: white;
    padding: 32px 48px;

    h3 {
      margin: 0;
    }
  }

  .center {
    text-align: center;
    cursor: pointer;
  }
}

form {
  margin: 0;
  padding: 3rem 3rem 1.875rem 3rem;
  background-color: $grey-2;

  .input-wrapper {
    margin-bottom: 1rem;

    input {
      background-color: white;
    }
  }

  .resend-verification {
    font-weight: 600;
    cursor: pointer;
  }

  button {
    display: block;
    margin: 2rem auto;
  }
}

.forgot-password {
  display: block;
  text-align: center;
  margin: 0.5rem 0;
}
</style>
