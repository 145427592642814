<template>
  <div class="reg-wrapper top-borders bottom-borders" v-if="!showLogin">
    <div class="top top-borders"><h3>Register</h3></div>

    <form @submit="onSubmit" class="bottom-borders">
      <div v-show="isHome"><p class="header-text">To view results, please register for a free account.</p></div>

      <div class="input-wrapper">
        <label>First name</label>
        <input
          type="text"
          placeholder="First name"
          v-model="first_name"
          :class="{
            error: first_nameErrors?.length && first_name,
            success: !first_nameErrors?.length && first_name
          }"
        />
      </div>
      <div class="input-wrapper">
        <label>Last name</label>
        <input
          type="text"
          placeholder="Last name"
          v-model="last_name"
          :class="{
            error: last_nameErrors?.length && last_name,
            success: !last_nameErrors?.length && last_name
          }"
        />
      </div>

      <div class="input-wrapper">
        <label>Email</label>
        <input
          type="email"
          placeholder="Email"
          v-model="email"
          :class="{
            error: emailErrors?.length && email,
            success: !emailErrors?.length && email
          }"
        />
        <div class="error" v-if="emailErrors.length && email">
          <ul>
            <li class="list-none" v-for="(error, i) of emailErrors" :key="i">{{ error }}</li>
          </ul>
        </div>
      </div>
      <div class="input-wrapper">
        <label>Password</label>
        <input
          type="password"
          placeholder="Password"
          v-model="password"
          :class="{
            error: passwordErrors?.length && password,
            success: !passwordErrors?.length && password
          }"
        />
        <div class="error" v-if="passwordErrors.length && password">
          <p>Your password needs to contain:</p>
          <ul>
            <li v-for="(error, i) of passwordErrors" :key="i">{{ error }}</li>
          </ul>
        </div>
      </div>
      <div class="input-wrapper">
        <label class="checkbox-label">
          <input
            type="checkbox"
            v-model="newsletter"
            :class="{
              error: newsletterErrors?.length && newsletter,
              success: !newsletterErrors?.length && newsletter
            }"
          />
          Please enroll me in your mailing list so I can get updates on the best practices and tips for website
          enhancement</label
        >
      </div>
      <button type="submit" :disabled="!meta.valid">Register</button>
    </form>

    <GoogleButton text="Register with Google" @clicked="google" />
    <div class="terms-notice">
      <p>
        By continuing, you are confirming that you have read, understood and agree to our
        <a target="_blank" href="/terms_and_conditions">terms and conditions</a>
      </p>
    </div>
    <div class="arrow padding-bottom">
      <p @click="switchToLogin()" class="center">Already have an account</p>
      <img class="chevron" src="@assets/icons/chevron-right.svg" />
    </div>
  </div>
  <LoginComponent v-else />
</template>

<script setup lang="ts">
import { redirectToGoogle } from '@/services/social-auth-service'
import GoogleButton from '@/components/ui/google-button/GoogleButton.vue'
import { useField, useForm } from 'vee-validate'
import { boolean, object, string } from 'yup'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import LoginComponent from './login/LoginComponent.vue'
import { ref, onMounted } from 'vue'
import { useToastStore } from '../../stores/toast'
import { useChallengeV3 } from 'vue-recaptcha'
import ButtonLoader from '../ui/button-loader/ButtonLoader.vue'

const { navigateUser, isHome } = defineProps({
  navigateUser: {
    type: Boolean,
    default: true
  },
  isHome: {
    type: Boolean,
    default: false
  }
})

const { execute } = useChallengeV3('submit')

const emit = defineEmits(['close'])
const store = useAuthStore()
const { alert } = useToastStore()
const router = useRouter()
const returnUrl = ref('/')
const showLogin = ref(false)
const schema = object({
  first_name: string().required(),
  last_name: string().required(),
  email: string().email('Please enter a valid email address').required(),
  newsletter: boolean(),
  password: string()
    .required()
    .min(8, 'At least 8 characters')
    .matches(/[a-z]/, 'A lowercase character')
    .matches(/[A-Z]/, 'An uppercase character')
    .matches(/[0-9\W]/, 'At least 1 number or symbol')
})

const { meta, handleSubmit } = useForm({
  validationSchema: schema,
  initialValues: {
    first_name: '',
    last_name: '',
    email: '',
    newsletter: false,
    password: ''
  }
})

const { value: email, errors: emailErrors, resetField } = useField(() => 'email')
const { value: password, errors: passwordErrors } = useField(() => 'password')
const { value: first_name, errors: first_nameErrors } = useField(() => 'first_name')
const { value: last_name, errors: last_nameErrors } = useField(() => 'last_name')
const { value: newsletter, errors: newsletterErrors } = useField(() => 'newsletter')

const response = ref()
const emailCopy = ref<string>('')

const onSubmit = handleSubmit(async (values) => {
  window.lintrk('track', { conversion_id: 20756625 })
  const token = await execute()
  emailCopy.value = email.value as string
  try {
    await store.registerByEmail({ ...values, token })
    router.push({ path: '/verification-message' })
    store.toggleRegister(false, true)
  } catch (error) {
    if (error.response.data.message == 'The email has already been taken.') {
      resetField({
        errors: ['This email address has already been taken.'],
        touched: true,
        value: emailCopy.value
      })
    } else {
      alert(error.response.data.message, 'error')
    }
  }
})

const switchToLogin = () => {
  store.toggleRegister(false, true)
}

const google = async () => {
  const res = await redirectToGoogle()
  window.open(res, '_self')
}
onMounted(() => {
  returnUrl.value = store.returnUrl
})
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.arrow {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;

  .chevron {
    height: 16px;
    width: 16px;
    margin-left: 0.5rem;
  }

  p {
    padding-bottom: 0;
  }
}

.padding-bottom {
  padding-bottom: 3rem;
}

.reg-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto 6.875rem auto;
  background-color: $grey-3;
  height: wrap-content;

  .top {
    background-color: $black;
    color: white;
    padding: 32px 48px;

    h3 {
      margin: 0;
    }
  }

  form {
    margin: 0;
    padding: 3rem 3rem 1.875rem 3rem;
    background-color: $grey-2;

    .input-wrapper {
      margin-top: 1rem;

      input {
        background-color: white;
      }
    }

    button {
      display: block;
      margin: 2rem auto 0 auto;
    }
  }

  .center {
    text-align: center;
    cursor: pointer;
  }
}

.terms-notice {
  padding: 0 3rem;
  text-align: center;
  margin-bottom: 32px;

  a {
    color: $black;
    text-decoration: underline;
    font-weight: 600;
  }
}

.checkbox-label {
  padding-left: 0;
  display: flex;
  gap: 1rem;

  input {
    width: 1.5rem;
    height: 1.5rem;
    accent-color: $signifier-success;
    appearance: none;
    -webkit-appearance: none;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 2rem;
    padding: 0.1rem;
    border-radius: 0.2rem;
    border: 1px solid $grey;

    &::before {
      content: '';
      width: 1.4rem;
      height: 1.4rem;
      clip-path: polygon(28% 38%, 41% 53%, 75% 24%, 86% 38%, 40% 78%, 15% 50%);
      transform: scale(0);
      background-color: $signifier-success;
    }

    &:checked::before {
      transform: scale(1.3);
    }
  }
}

.header-text {
  margin-bottom: 32px;
}
</style>
